
import { computed, defineComponent, onMounted, ref, watch } from 'vue'
import { knowledgeBaseArticle } from '../../types/main'
import api from '../../api/api'
export default defineComponent({
    props: {
        articleId: {
            type: String,
            required: true,
        },
    },
    setup(props) {
        const article = ref<knowledgeBaseArticle>()
        const loading = ref(true)

        const articleHtml = computed(() => article.value?.html)
        const articleTitle = computed(() => article.value?.title)

        const init = async () => {
            loading.value = true
            article.value = await api.knowledgeBase.getArticle(props.articleId).then(res => res.data!)
            loading.value = false
        }
        watch(() => props.articleId, init, { immediate: true })
        return { article, articleHtml, articleTitle }
    },
})
